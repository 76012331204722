



















import { Component, Vue } from 'vue-property-decorator'
import { Auth } from '@/services/Auth'
import constants from '@/constants'
import AonButton from '@/components/AonButton.vue'
import { ACTION_RESET_SALE_JOURNEY_STATE } from '@/store/modules/product/actionTypes'

@Component({
  components: {
    AonButton
  }
})
export default class Expire extends Vue {
  goHome() {
    if (Auth.loggedInAs('otp')) {
      this.$router.replace({ name: constants.routeNames.DASHBOARD })
    } else {
      this.$router.replace({ name: constants.routeNames.HOME })
    }
  }

  mounted() {
    this.$store.dispatch(ACTION_RESET_SALE_JOURNEY_STATE)
  }
}
